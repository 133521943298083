export const updates = [
  {
    date: "2024/10/09",
    message: ["Version 2.1.3 released: Migration alert removed."],
  },
  {
    date: "2024/10/03",
    message: [
      "Version 2.1.2 released. <ul>" +
        "<li><b>ADMIN</b>: Add automation system to station stream details</li>" +
        "<li><b>UI</b>: Notification modal added for scheduled migration on October 9th (10am - 12pm ET) alerting</li>" +
        "</ul>",
    ],
  },
  {
    date: "2024/09/10",
    message: [
      "Version 2.1.1 released. <ul>" +
        "<li><b>NEW</b>: SCTE Insights added to Event Monitor for SCTE warning context </li>" +
        "<li><b>NEW</b>: User configs now saved to database for easier access across devices</li>" +
        "<li><b>UI</b>: App version accessible in navigation for better troubleshooting</li>" +
        "<li><b>UI</b>: New home screen with quick access to favorites and 101 SyncMon info</li>" +
        "<li><b>UI</b>: Updated navigation for easier access to stations, search and favorites</li>" +
        "<li><b>UI</b>: New collapsed state for navigation to maximize screen space</li>" +
        "<li><b>UI</b>: Updated header with breadcrumbs for quicker navigation back to streams and stations</li>" +
        "<li><b>UI</b>: Softron added as a new video source for stream settings</li>" +
        "<li><b>NEW</b>: Additional FAST sources add for Amazon and Vizio</li>" +
        "<li><b>FIX</b>: Video Monitoring now uses station's local timezone for event debugging</li>" +
        "<li><b>FIX</b>: Switched references to SyncBak to Zeam for clarity</li>" +
        "<li><b>FIX</b>: Removed forced handling of legacy Gray email domain</li>" +
        "<li><b>FIX</b>: Better abort controller handling for async data requests</li>" +
        "<li><b>FIX</b>: Prevent error warning before new stream data is fetched</li>" +
        "<li><b>FIX</b>: General performance improvements, refactoring, and optimizations </li>" +
        "</ul>",
    ],
  },
  {
    date: "2024/07/22",
    message: [
      "Version 2.1.009 released. <ul>" +
        "<li><b>NEW</b>: Overview Dashboard released to site admins</li>" +
        "<li><b>NEW</b>: Hide Telemundos from Overview Dashboard SCTE alerting</li>" +
        "<li><b>NEW</b>: Add ability to hide admin/superadmin features for demos</li>" +
        "<li><b>NEW</b>: Streaming partner distribution list updated</li>" +
        "<li><b>NEW</b>: New 'Video source' for 'vMix' added to stream settings config</li>" +
        "<li><b>NEW</b>: New 'Trigger Type' for 'timed schedule' added to stream settings config</li>" +
        "<li><b>NEW</b>: New 'Trigger Type' for 'Full SCTE' added to stream settings config</li>" +
        "<li><b>UI</b>: General style and performance improvements</li>" +
        "<li><b>UI</b>: Knowledge Base updates to reflect platform changes</li>" +
        "<li><b>UI</b>: Visualization fo 'Ad Break Ends' now different from starts (smaller and softer in colour)</li>" +
        "<li><b>FIX</b>: 'Ad Analysis' now available for 'Full SCTE' and 'Timed Schedules' workflow</li>" +
        "<li><b>FIX</b>: 'Event Monitor' button now visible from 'Ad Analysis'</li>" +
        "<li><b>FIX</b>: 'Event Timeline' view no longer reverses with data updates</li>" +
        "<li><b>FIX</b>: 'Event Monitor' table filter remain in place after async data updates</li>" +
        "<li><b>FIX</b>: 'Ad Break Analysis' properly colours actual duration bars for comparison to expected duration</li>" +
        "<li><b>FIX</b>: 'Overview Dashboard' still required superadmin permissions</li>" +
        "<li><b>FIX</b>: Email domain change from gray.tv to graymedia.com supported for admins/superadmins</li>" +
        "</ul>",
    ],
  },
  {
    date: "2024/02/23",
    message: [
      "Version 2.1.008 released. <ul>" +
        "<li><b>TESTING</b>: Testing new 'Overview' page for more efficient troubleshooting (superadmin only)" +
        "<li><b>NEW</b>: Added new 'Event Monitor' range option of past 6 hours</li>" +
        "<li><b>NEW</b>: VOD META data surfaced in 'Video Monitor' including title, description, duration, ad breaks, and more</li>" +
        "<li><b>NEW</b>: Superadmin can now hide a station from displaying in station menu</li>" +
        "<li><b>New</b>: New livestream added to LNL for Softron testing</li>" +
        "<li><b>UI</b>: Improvements to landscape and portrait views on Tablets</li>" +
        "<li><b>UI</b>: Subtle changed to design with inclusions of cards and condensing of elements</li>" +
        "<li><b>UI</b>: Eliminated empty local & blackout events from 'Event Monitor' for clarity</li>" +
        "<li><b>UI</b>: Ordered CUE-INs before paired events to clarity in timeline</li>" +
        "<li><b>FIX</b>: Zeam API change caused overlapping events. Event percentage corrected</li>" +
        "</ul>",
    ],
  },
  {
    date: "2024/02/05",
    message: [
      "Version 2.1.007 released. <ul>" +
        "<li>New Vizio livestreams added to Video Monitor</li>" +
        "<li>Timeline view extended to 5 days for better troubleshooting</li>" +
        "<li>New overview being tested with superadmins</li>" +
        "<li><b>FIX:</b> timeline view was being reversed</li>" +
        "</ul>",
    ],
  },
  {
    date: "2024/01/04",
    message: ["Zeam has released an update to their platform that restores the functionality of the <b>Gray SyncMon</b>."],
  },
  {
    date: "2023/12/14",
    message: [
      "On December 14th, <b>Zeam</b> released an update to introduce SSO across their platforms that broke some event monitoring " +
        "functionality on the <b>Gray SyncMon</b>",
      "This includes:",
      "<ul>" +
        "<li>Viewing active GPI triggers</li>" +
        "<li>Reviewing live stream events &amp; information</li>" +
        "<li>Highlighting alerts/errors on SyncBoxes. </li>" +
        "<li>Ad break analysis</li>" +
        "<li>Timeline view of events</li>" +
        "</ul>",
      "All <b>SyncMon</b> &gt; <b>Video Monitor</b> functionality remains operational.",
      "<b>Zeam</b> is aware of the issue and is working to resolve it as soon as possible. We will update this notification when the issue has been resolved.",
    ],
  },
  {
    date: "2023/12/11",
    message: [
      "Version 2.1.005<ul>" +
        "<li>Bug fix where AWS Datastore was not loading for some users</li>" +
        "<li>CSS tweaks to improve station, stream & video list UI</li>" +
        "</ul>",
    ],
  },
  {
    date: "2023/12/06",
    message: [
      "Versions 2.1.004 released with primary focus on Okta SSO wrapping for authentication <ul>" +
        "<li>Converted authentication to Okta</li>" +
        "<li>Full removal of AWS Cognito and OneLogin</li>" +
        "<li>Account info now available from the top app bar</li>" +
        "<li>Moved 'pin nav' to the bottom of the sidebar</li>" +
        "</ul>",
    ],
  },
  {
    date: "2023/11/29",
    message: [
      "Version 2.1.003 released. <ul>" +
        "<li>Pin navigation panel. Helpful when reviewing multiple stations</li>" +
        "<li>Search by station affilations (ie: NBC, CBS, etc)</li>" +
        "<li>Testing <b>superadmin</b> functionality to designate station as owned or operated</li>" +
        "<li>Testing <b>superadmin</b> functionality to hide individual stations in navigation</li>" +
        "</ul>",
    ],
  },
  {
    date: "2023/11/20",
    message: [
      "Version 2.1.002 released. <ul>" +
        "<li>Video Monitor now highlights FAST Channels, Live Streams, & VODs.</li>" +
        "<li>GraphQL credentials added for missing stations</li>" +
        "<li>Gray API expanded to allow for live and vod lists, as well as individal queries</li>" +
        "<li>Displaying <b>Program ID</b> in Event Monitors when passed</li>" +
        "</ul>",
    ],
  },
  {
    date: "2023/11/17",
    message: [
      "Version 2.1.001 released with focus on admin tools, UI improvements, and bug fixes.",
      "<b>Updates include:</b><ul>" +
        "<li>Both 'Show GPIs' and 'Show Hidden' options now exposed to all users</li>" +
        "<li>'Ad Break Analysis' for Middleman station now available directly on Event Monitor page for admins</li>" +
        "<li>Eliminating distractionn in UI by hiding station list after selecting a stream to review</li>" +
        "<li>Better surfacing of key information for streams with new stream details panel</li>" +
        "<li>Correct relationships data (ie: Trigger type to streams rather than station)</li>" +
        "<li>New admin functionality to categorize streams: News, Weather, Spanish, etc</li>" +
        "<li>New admin functionality to define trigger type (ie: Middleman, TBW, etc)</li>" +
        "<li>DB clean up of duplicate 'stream.ssid' rows from earlier development</li>" +
        "<li>Additional GA4 tracking of pages and events</li>" +
        "<li>Improved route handling for more consistent navigation experience</li>" +
        "<li>Modified header, station navigation & secondary navigation for better UX</li>" +
        "</ul>",
    ],
  },
  {
    date: "2023/11/09",
    message: [
      "Version 2.1 of the SyncMonitor has been released. This release includes an updated UI, bug fixes, and a new optimized backend which will allow for more frequent updates and new features to be added more quickly.",
      "<b>Bug Fixes</b>: <ul>" +
        "<li>Admin tools work with stations labelled as LD now</li>" +
        "<li>Some hidden streams were still displaying in Stream List</li>" +
        "<li>Filtering was affecting display of stream event chart and high level view</li>" +
        "<li>Filtering were an event was not found was causing the app appear to be continously loading</li>" +
        "<li>Event and Video Monitor navs were not highlighting the selected station</li>" +
        "<li>Pie chart data was bleeding outside of the container div</li>" +
        "</ul>",
    ],
  },
];
