// Moment.js Components
import moment from "moment-timezone";

// Gray Digital Components & Utilities
import { getTz, sortTime } from "./utility";
import { fetchWithRetry, handleApiResponse } from "./apis";

const API_URL = "https://chwtgazeif.execute-api.us-east-1.amazonaws.com/default/syncmonitor-api/logs";

export const warningCheck = (data) => {
  return (
    data.isBegin && (data.EstimatedDuration <= 20 || (data.EventType === 0 && data.EstimatedDuration >= 360) || data.EstimatedDuration >= 1000000)
  );
};

export const prepRow = (num, data) => {
  return {
    ...data,
    id: num,
    seconds: data.EstimatedDuration.toLocaleString("en-US"),
    warning: warningCheck(data),
    duration: moment.utc().startOf("day").seconds(data.EstimatedDuration).format("HH:mm:ss"),
  };
};

/* New function to clean the response */
const cleanResponse = (logs) => {
  const timeframe = { start: 0, end: 0, delta: 0 };
  const tempLogs = [...logs];

  /* Get the true timeframe for the stream by ignoring any active event where duration is zero */
  for (let i = 0; i < tempLogs.length; i++) {
    if (tempLogs[i].EstimatedDuration > 0) {
      timeframe.end = moment.utc(tempLogs[i].Time).add(tempLogs[i].EstimatedDuration, "seconds");
      break;
    }
  }
  if (timeframe.end !== 0) {
    timeframe.start = moment.utc(tempLogs[tempLogs.length - 1].Time);
    timeframe.delta = timeframe.end.diff(timeframe.start, "seconds");
  }

  /* Separate the logs into the three event types (array) */
  const eventTypes = [];
  const cueIns = [];

  [
    { code: 0, name: "Ad Break" },
    { code: 1, name: "Blackout / LNL" },
    { code: 2, name: "Streaming" },
  ].forEach((val) => {
    const response = tempLogs.filter((e, k) => {
      if (e.EventType === val.code && e.IsBegin && e.EstimatedDuration > 0) {
        return true;
      } else if (val.code === 0 && e.EventType === 0 && !e.IsBegin) {
        e.Time = moment.utc(e.Time).subtract(1000, "milliseconds").format();
        cueIns.push(e);
        return false;
      } else if (e.EventType === val.code && e.IsBegin && e.EstimatedDuration === 0) {
        //&& k === tempLogs.length - 1) {
        return true;
      } else {
        return false;
      }
    });
    eventTypes[val.code] = response;
  });

  /* Look for any Ad Breaks that have the same start time as a Local or Blackout event */
  const eventStarts = [];
  eventTypes[0].forEach((val, key) => {
    eventTypes[1].some((e, k) => {
      if (val.Time === e.Time && val.IsBegin) {
        /* Echo to console when a Blackout event is matched */
        console.log("Blackout Matched", val, e);
        eventStarts.push([val, e]);
        eventTypes[1].splice(k, 1);
        return true;
      }
      return false;
    });

    eventTypes[2].some((e, k) => {
      if (val.Time === e.Time && val.IsBegin && val.EstimatedDuration > 0) {
        eventTypes[0][key].fixedDuration = eventTypes[0][key].EstimatedDuration;
        eventTypes[0][key].EstimatedDuration = e.EstimatedDuration;
        eventTypes[0][key].duration = e.duration;
        eventTypes[0][key].seconds = e.seconds;

        eventStarts.push([val, e]);
        eventTypes[2].splice(k, 1);
        return true;
      }
      return false;
    });
  });

  return eventTypes[0].concat(eventTypes[1], eventTypes[2], cueIns);
};

export const getEventLogs = async ({ startDate, endDate, timezone, stream, abortController }) => {
  const formattedStartDate = moment.utc(startDate).format();
  const formattedEndDate = moment.utc(endDate).format();

  try {
    const url = `${API_URL}?ssid=${stream.ssid}&start=${formattedStartDate}&end=${formattedEndDate}`;
    const data = await fetchWithRetry(url, { signal: abortController.signal });

    // Check if the fetch was aborted
    if (abortController.signal.aborted) {
      throw new Error("Fetch aborted");
    }

    // Return the parsed response or an error message if any
    const { apiResponse, errorMsg } = handleApiResponse(data);
    if (errorMsg) {
      return { apiResponse: null, filteredResponse: null, totalDuration: 0, errorMsg };
    }

    if (apiResponse.events) {
      let i = 1;
      let results = [];

      apiResponse.events = sortTime(apiResponse.events);
      apiResponse.events = apiResponse.events.filter((e) => {
        if (e.EventType > 0 && e.IsBegin === true) {
          return true;
        } else if (e.EventType === 0) {
          return true;
        }
        return false;
      });

      // Step 1: Identify the most recent time in the array
      const mostRecentTime = apiResponse.events.reduce((latest, event) => {
        return new Date(event.Time) > new Date(latest) ? event.Time : latest;
      }, apiResponse.events[0].Time);

      // Step 2: Convert time to seconds for comparison
      const timeToSeconds = (time) => Math.floor(new Date(time).getTime() / 1000);

      // Step 3: Determine if there’s an EventType === 1 in the most recent subset
      const hasEventType1 = apiResponse.events.some((event) => event.EventType === 1 && timeToSeconds(event.Time) === timeToSeconds(mostRecentTime));

      // Step 4: Filter out EventType === 2 in the most recent subset if EventType === 1 exists
      const filteredEvents = apiResponse.events.filter((event) => {
        const isMostRecent = timeToSeconds(event.Time) === timeToSeconds(mostRecentTime);

        // Keep EventType === 2 in the most recent subset only if no EventType === 1 is present
        if (isMostRecent && event.EventType === 2 && hasEventType1) {
          return false; // Remove this EventType === 2
        }

        // Keep all other events
        return true;
      });

      console.log(filteredEvents);
      apiResponse.events = filteredEvents;

      apiResponse.events.forEach((e, key) => {
        delete e.ID;

        if (e.EventType === 0 || (e.EventType > 0 && e.IsBegin)) {
          // 2024-11-13 : change Estimated to seconds with 2 decimal places
          e.EstimatedDuration =
            e.EstimatedDuration / 1000000000 > 59 ? Math.floor(e.EstimatedDuration / 1000000000) : Math.floor(e.EstimatedDuration / 10000000) / 100; // convert to seconds
          if (e.EstimatedDuration === 0 && e.EventType === 2) console.log(stream.sname + " Zero Duration", e);
          results.push(prepRow(i, e));
          i++;
        }
      });

      let altDuration = 0;
      results.forEach((item) => {
        altDuration += +item.EstimatedDuration;
      });

      let active = results[0];

      results = sortTime(cleanResponse(results));

      results.reverse().forEach((e) => {
        const datetime = moment.tz(e.Time, getTz(timezone));
        const end_datetime = moment.tz(e.Time, getTz(timezone)).add(e.EstimatedDuration, "seconds");

        if (
          e.IsBegin &&
          datetime >= moment.tz(active.Time, getTz(timezone)) &&
          end_datetime <= moment.tz(active.Time, getTz(timezone)).add(active.EstimatedDuration, "seconds")
        ) {
          // Within active event
        } else {
          active = e;
        }
      });

      return {
        apiResponse: apiResponse.events,
        filteredResponse: results,
        totalDuration: altDuration,
        errorMsg: null,
      };
    } else {
      console.log("Fetch Event Logs: Empty");
      return {
        apiResponse: apiResponse.events,
        filteredResponse: null,
        totalDuration: 0,
        errorMsg: `No event logs exist for stream ${stream.ssname} (${stream.ssid})`,
      };
    }
  } catch (error) {
    if (error.name === "AbortError") {
      return {
        apiResponse: null,
        errorMsg: "Error fetching getEventLogs() logs from Zeam API ... Request aborted",
      };
    }

    if (error.message === "Fetch aborted") return;

    return {
      apiResponse: null,
      filteredResponse: null,
      totalDuration: 0,
      errorMsg: "Error fetching logs from Zeam API ... Final attempt failed. Please retry reloading the page or reach out to Zeam",
    };
  }
};
