// MUI Components
import { Box, Tooltip } from "@mui/material";

// Moment.js Components
import moment from "moment-timezone";
import { WarningOutlined } from "@mui/icons-material";
import { yellow } from "@mui/material/colors";

// Gray Components & Utilities
import EventType, { EventName } from "../components/eventMonitor/EventType";
import { getTz } from "./utility";

export const getEventLogColumns = ({ timezone }) => {
  return [
    {
      field: "EventType",
      headerName: "Event",
      headerClassName: "head",
      maxWidth: "2rem",
      flex: 1,
      renderCell: (params) => {
        return <EventType type={params?.row.EventType} isBegin={params?.row.IsBegin} />;
      },
    },
    {
      field: "Time",
      headerName: "Date / Time",
      headerClassName: "head",
      minWidth: "14rem",
      flex: 4,
      sortable: true,
      renderCell: (params) => {
        return moment.tz(params?.row.Time, getTz(timezone)).format("MMM D, YYYY @ h:mm:ss.SSS a z");
      },
    },
    {
      field: "IsBegin",
      headerName: "Label",
      headerClassName: "head",
      minWidth: "7rem",
      flex: 3,
      renderCell: (param) => {
        return <EventName type={param?.row.EventType} isBegin={param?.row.IsBegin} />;
      },
    },
    {
      field: "ProgramID",
      headerName: "Program ID",
      headerClassName: "head",
      minWidth: "2rem",
      headerAlign: "center",
      align: "center",
      flex: 2,
      renderCell: (params) => {
        return params?.row.ProgramID ? (
          parseInt(params?.row.ProgramID, 16)
        ) : (
          <span
            className="false"
            style={{
              textAlign: "center",
              display: "block",
            }}
          >
            &mdash;
          </span>
        );
      },
    },
    {
      field: "warning",
      headerName: "Warning",
      headerClassName: "head",
      minWidth: "10rem",
      flex: 2,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Box className="eventlogs___table_center">
            {params?.row.warning === true ? (
              <Tooltip title="Event is unusually short or long in duration" arrow placement="right">
                <WarningOutlined style={{ color: yellow[800] }} />
              </Tooltip>
            ) : (
              ""
            )}
          </Box>
        );
      },
    },
    {
      field: "EstimatedDuration",
      headerName: "Seconds",
      headerClassName: "head",
      minWidth: "5rem",
      flex: 2,
      headerAlign: "right",
      align: "right",
      renderCell: (params) => {
        return params?.row.IsBegin === true ? (
          params?.row.seconds
        ) : (
          <span
            className="false"
            style={{
              textAlign: "center",
              display: "block",
            }}
          >
            &mdash;
          </span>
        );
      },
    },
    {
      field: "duration",
      headerName: "Duration",
      headerClassName: "head",
      minWidth: "5rem",
      flex: 2,
      headerAlign: "right",
      align: "right",
      renderCell: (params) => {
        return params?.row.IsBegin === true ? (
          params?.row.duration
        ) : (
          <span
            className="false"
            style={{
              textAlign: "center",
              display: "block",
            }}
          >
            &mdash;
          </span>
        );
      },
    },
  ];
};
