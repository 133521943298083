// TODO: Review new Spreadsheet from Clay

// React Components
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";

// React Router Dom Components
import { Route, Routes } from "react-router-dom";

// Material UI Components
import { CircularProgress, Container, Typography } from "@mui/material";

// AWS Amplify Components
import { API, DataStore, SortDirection, graphqlOperation } from "aws-amplify";
import { listPermissions, listUsers } from "../graphql/queries";
import { createUsers, updateUsers } from "../graphql/mutations";
import { EventNotes, Stations } from "../models";

// Gray TV Components
import { initialSize } from "../utilities/getInitializedSizes";
import { Authentication } from "../context/Authentication";
import { getStations } from "../utilities/getStations";
import { Application } from "../context/Application";
import { getTz } from "../utilities/utility";

import LoadingPhrases from "../components/navigation/LoadingPhrases";
import WelcomeLayout from "../layouts/WelcomeLayout";
import AdminLayout from "../layouts/AdminLayout";

// Gray Assets
import "../_styles/BetaApp.scss";

const appVersion = "2.1.3";

const AdminScreen = ({ authState }) => {
  const userState = useContext(Authentication);

  const update_frequency = 60000;
  const [appUser, setAppUser] = useState({
    email: authState.idToken.claims.email.toLowerCase(),
    name: authState.idToken.claims.name,
    expiry: authState.accessToken.claims.exp,
    account: null,
  });

  const [appDates, setAppDates] = useState({
    start: moment.tz(getTz()).subtract(3, "days"),
    startMin: moment.tz(getTz()).subtract(14, "days"),
    startMax: moment.tz(getTz()).subtract(1, "hour"),
    end: moment.tz(getTz()),
    endMin: moment.tz(getTz()).subtract(14, "days").add(1, "hour"),
    endMax: moment.tz(getTz()),
    timezone: "Eastern Standard Time",
  });

  // Stream & Station Vars
  const [stationList, setStationList] = useState(null); // ARRAY
  const [activeStream, setActiveStream] = useState(null);
  const [activeStation, setActiveStation] = useState(null);
  const [favoriteList, setFavoriteList] = useState([]);

  const [appUI, setAppUI] = useState({
    appVersion: appVersion,
    refresh: 12,
    height: initialSize.height,
    width: initialSize.width,
    error: null,
    showHelp: false,
    showComments: false,
    showMobile: true,
    showSearch: false,
    showFavorites: false,
    showStations: true,
    showTelemundos: false,
    hideAdminTools: false,
    pinNav: false,
    helpTopic: "dashboard",
    forceUpdate: 0,
    memoryDebug: true,
    section: "eventsTable",
    searchTerm: null,
  });

  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    const updateDates = () => {
      const currentTime = moment.tz(getTz(appDates.timezone));

      const newDates = {
        startMin: currentTime.clone().subtract(14, "days"),
        startMax: currentTime.clone().subtract(1, "hour"),
        endMin: currentTime.clone().subtract(14, "days").add(1, "hour"),
        endMax: currentTime.clone(),
        ...(appUI.refresh && {
          start: currentTime.clone().subtract(appUI.refresh, "hours"),
          end: currentTime.clone(),
        }),
      };

      setAppDates((prev) => ({
        ...prev,
        ...newDates,
      }));
    };

    updateDates(); // Initial call to update dates

    const interval = setInterval(updateDates, update_frequency);

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUI.refresh, appDates.timezone]);

  const debounce = useCallback((func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }, []);

  useEffect(() => {
    const handleResize = debounce(() => {
      setAppUI((prev) => ({
        ...prev,
        height: window.innerHeight,
        width: window.innerWidth,
      }));
    }, 250);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const clearStorageAndDataStore = async () => {
      try {
        setIsLoading(true); // Set loading state to true
        console.log("-----------------------------------");
        console.log("DataStore:");

        // Start DataStore to sync
        await DataStore.start();
        console.log("   - Re-syncing initiated");

        // Observe & wait for the sync to complete
        const subscription = DataStore.observeQuery(Stations).subscribe(
          ({ items, isSynced }) => {
            if (isSynced) {
              console.log(`   - Syncing complete: ${items.length}`);
              setIsLoading(false);
              subscription.unsubscribe(); // Unsubscribe once the sync is complete
            } else {
              console.log("   - Syncing...");
            }
          },
          (error) => {
            console.log("   - Error syncing DataStore:", error);
            setIsLoading(false);
          }
        );
      } catch (error) {
        console.log("   - Error initializing DataStore:", error);
      }
    };

    clearStorageAndDataStore();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const email = authState.idToken.claims.email.toLowerCase();
        const name = authState.idToken.claims.name;

        // Check if user exists in the database
        console.log("-----------------------------------");
        console.log("User check: ", email);

        const response = await API.graphql(graphqlOperation(listUsers, { filter: { email: { eq: email } } }));
        const user = response?.data?.listUsers?.items[0];

        if (user) {
          // Update the global state with the user's favorites
          const favs = JSON.parse(user.favorites).sort();
          setFavoriteList(favs);

          await API.graphql(
            graphqlOperation(updateUsers, {
              input: { id: user.id, appVersion: appVersion, lastLogin: new Date().toISOString(), _version: user._version },
            })
          );
          console.log("   - Updating last login: ", user.lastLogin);
        } else {
          await API.graphql(
            graphqlOperation(createUsers, {
              input: {
                email: email,
                name: name,
                favorites: "[]",
                showHidden: false,
                showGPI: false,
                showTelemundos: false,
                appVersion: appVersion,
                lastLogin: new Date().toISOString(),
              },
            })
          );

          console.log("   - Creating New User: ", email);
        }
      } catch (error) {
        console.log("   - Error with User Check or Creation:", error);
        global.appUI((prev) => ({ ...prev, error: "Failed to check user" }));
      }
    };

    /* Fetch Stations */
    const fetchStations = async () => {
      const abortController = new AbortController();

      /* Get Stations On Load */
      try {
        const response = await getStations(abortController);
        if (response?.error) {
          setAppUI((prev) => ({ ...prev, error: response.error }));
        } else if (response && response.length > 0) {
          getCommentsAndVisibility(response);
        }
      } catch (error) {
        console.log("  - Error fetching stations:", error);
        setAppUI((prev) => ({ ...prev, error: "Failed to fetch stations" }));
      }

      return () => abortController.abort();
    };

    /* Check User Permissions */
    const checkPermissions = async () => {
      try {
        const email = authState.idToken.claims.email.toLowerCase();
        console.log("-----------------------------------");
        console.log("Permission check: ", email);

        if (email && !global?.appUser?.account) {
          const response = await API.graphql(graphqlOperation(listPermissions, { filter: { email: { eq: email } } }));
          const permissionResponse = response?.data?.listPermissions?.items[0];

          if (permissionResponse) {
            setAppUser((prev) => ({ ...prev, account: permissionResponse.permission }));
            console.log(`   - Type: ${permissionResponse.permission}`);
          }
        }
      } catch (error) {
        console.log("   - Error fetching user data:", error);
      }
    };

    const initializeData = async () => {
      if (!isLoading) {
        await Promise.all([checkUser(), fetchStations(), checkPermissions()]);
      }
    };

    initializeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const getCommentsAndVisibility = useCallback(async (stations) => {
    try {
      // Fetch comments, visibility, and ownership concurrently
      const [comments, hiddenStations, ssaStations] = await Promise.all([
        DataStore.query(EventNotes, (c) => c.and((c) => [c.deletedAt.eq(null), c.status.eq("OPEN")]), {
          sort: (s) => s.station(SortDirection.ASCENDING).createdAt(SortDirection.DESCENDING),
        }),
        DataStore.query(Stations, (c) => c.hidden.eq(true)),
        DataStore.query(Stations, (c) => c.ssa.eq(true)),
      ]);

      // Helper function to reduce data into maps
      const reduceToMap = (items, key) =>
        items.reduce((acc, item) => {
          acc[item[key]] = (acc[item[key]] || 0) + 1;
          return acc;
        }, {});

      const stationComments = reduceToMap(comments, "station");
      const boxComments = reduceToMap(comments, "ssid");

      // Create visibility and SSA maps
      const createBooleanMap = (items, key) =>
        items.reduce((acc, item) => {
          acc[item[key]] = true;
          return acc;
        }, {});

      const hidden = createBooleanMap(hiddenStations, "station");
      const ssa = createBooleanMap(ssaStations, "station");

      // Update the station list with comments, visibility, and SSA ownership
      const updatedStations = stations.map((station) => {
        const stationKey = station.station.toLowerCase();

        return {
          ...station,
          notes: stationComments[stationKey] || 0,
          hidden: hidden[stationKey] || false,
          ssa: ssa[stationKey] || false,
          boxes: Object.keys(station.boxes).reduce((updatedBoxes, boxKey) => {
            updatedBoxes[boxKey] = {
              ...station.boxes[boxKey],
              notes: boxComments[boxKey] || 0,
            };
            return updatedBoxes;
          }, {}),
        };
      });

      // Update the station list and set the active station
      setStationList(updatedStations);
    } catch (error) {
      console.log("   - Error fetching comments and visibility:", error);
      setAppUI((prev) => ({ ...prev, error: "Failed to fetch comments and visibility" }));
    }
  }, []);

  useEffect(() => {
    userState.setAuthState(authState);
    localStorage.setItem("SyncMon-User", authState.idToken.claims.email.toLowerCase());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoizedProviderValue = useMemo(
    () => ({
      appUI,
      appUser,
      appDates,
      setAppUI,
      setAppUser,
      setAppDates,
      stationList,
      favoriteList,
      activeStream,
      activeStation,
      setStationList,
      setFavoriteList,
      setActiveStream,
      setActiveStation,
    }),
    [appUI, appUser, appDates, stationList, favoriteList, activeStream, activeStation]
  );

  if (isLoading) {
    // Optionally, you can show a loading spinner or any other loading indicator here
    return (
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
        <Typography variant="body1" sx={{ marginTop: "1rem", color: "#36c", fontSize: "1.25rem" }}>
          Fetching Data from the Zeam API
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center", marginTop: "2rem", color: "#444" }}>
          <LoadingPhrases />
        </Typography>
      </Container>
    );
  }

  return (
    <Application.Provider value={memoizedProviderValue}>
      <Routes>
        <Route path="/">
          <Route path="overview" element={<AdminLayout route="overview" />} />
          <Route path="ad_analysis">
            <Route path=":stationid/:ssid" element={<AdminLayout route="ad_analysis" />} />
          </Route>
          <Route path="timeline">
            <Route path=":stationid/:ssid" element={<AdminLayout route="eventsTimeline" />} />
          </Route>
          <Route path="video">
            <Route path=":stationid/distro/:distro" element={<AdminLayout route="video" />} />
            <Route path=":stationid/live/:live" element={<AdminLayout route="video" />} />
            <Route path=":stationid/vod/:vod" element={<AdminLayout route="video" />} />
            <Route path=":stationid" element={<AdminLayout route="video" />} />
          </Route>
          <Route path="view">
            <Route path=":stationid/:ssid/:startdate/:enddate" element={<AdminLayout route="eventsTable" />} />
            <Route path=":stationid/:ssid" element={<AdminLayout route="eventsTable" />} />
            <Route path=":stationid" element={<AdminLayout route="eventsTable" />} />
          </Route>
          <Route index element={<WelcomeLayout route="welcome" />} />
          <Route index element={<AdminLayout route="eventsTable" />} />
        </Route>
      </Routes>
    </Application.Provider>
  );
};

export default AdminScreen;
