/* Check if Hidden */
export const isHidden = ({ source, id }) => {
  const obj = source.find((stream) => +stream.ssid === +id && stream.hidden === true);
  return obj?.hidden ?? false;
};

export const getRouteName = (data) => {
  const routeNames = {
    ad_analysis: "Ad Analysis",
    eventsTable: "Event Monitor",
    eventsTimeline: "Event Timeline",
    video: "Video Monitor",
  };
  return routeNames[data] || "view";
};

export const getRoutePath = (data) => {
  const routePaths = {
    ad_analysis: "view",
    eventsTable: "view",
    eventsTimeline: "view",
    video: "video",
  };
  return routePaths[data] || "view";
};

export const getCategory = (data) => {
  const categories = {
    NEWS: "News Channel",
    BREAKING: "24/7 Breaking News",
    WEATHER: "24/7 Weather",
    TRAFFIC: "24/7 Traffic",
    ALT: "Alt Network",
    CAMS: "Tower Cams",
    SPANISH: "Spanish Language",
    ARC: "Arc XP",
  };
  return categories[data] || "Unknown";
};

export const getTriggers = (data) => {
  const triggers = {
    SNOWFLAKE: "Non-Traditional",
    MIDDLEMAN: "Middleman",
    DBI: "DBi",
    UNDEFINED: "Undefined",
    TIMED: "Timed Scheduling",
    FULLSCTE: "Full SCTE",
  };
  return triggers[data] || "Trigger Based";
};

export const getAutomation = (data) => {
  const automation = {
    ADC: "ADC",
    CRISPIN: "Crispin",
    DBI: "DBi",
    FLORICAL: "Florical",
    ITX: "ITX",
    MORPHEUS: "Morpheus",
    NVERZION: "NVerzion",
    UNDEFINED: "Undefined",
  };
  return automation[data] || "Undefined";
};

export const getInputs = (type) => {
  const inputs = {
    SOURCE1: "Source 1",
    SOURCE2: "Source 2",
    OTHER: "Undefined",
  };
  return inputs[type] || "Undefined";
};

export const getSources = (data) => {
  const sources = {
    MCR: "Master Control Room",
    PCR: "Production Control Room",
    ELEMENTAL: "AWS Elemental",
    BRIGHTSIGN: "BrightSign",
    DBI: "DBi",
    JVC: "JVC",
    SOFTRON: "Softron",
    OBS: "OBS",
    VMIX: "vMix",
    WEATHERMETRICS: "Weather Metrics",
    WEATHERNATION: "WeatherNation",
    WEATHERMAX: "Weather MAX",
    WIRECAST: "WireCast",
    CUSTOM: "Custom Build",
  };
  return sources[data] || null;
};

export const getTz = (data) => {
  const timezones = {
    "Eastern Standard Time": "America/New_York",
    "Central Standard Time": "America/Chicago",
    "Mountain Standard Time": "America/Denver",
    "Pacific Standard Time": "America/Los_Angeles",
    "Alaskan Standard Time": "America/Anchorage",
    "Hawaiian Standard Time": "Pacific/Honolulu",
  };
  return timezones[data] || "America/New_York";
};

export const compareMVPD = (a, b) => a.mvpd.localeCompare(b.mvpd);

export const sortMVPD = (data) => Object.values(data).sort(compareMVPD);

export const sortTime = (data) => [...data].sort((a, b) => new Date(b.Time) - new Date(a.Time));

export const compareStations = (a, b) => a.station.localeCompare(b.station);

const sortStations = (data) => data.sort(compareStations);

export default sortStations;
