import React, { memo, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";

// Material UI Components
import { Info } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material";

// Gray TV Components
import { Application } from "../context/Application";
import { getTz, sortMVPD } from "../utilities/utility";

import MainSidebar from "../components/navigation/MainSidebar";
import Header from "../components/templates/Header";
import SelectedStation from "./SelectedStation";
import Overview from "../views/Overview";

const AdminLayout = ({ route }) => {
  const modalActive = false;

  const global = useContext(Application);
  const [showModal, setShowModal] = useState(false);

  // Destructure URL Params
  const { stationid: uid, ssid, startdate, enddate } = useParams();

  // Set route in global state
  useEffect(() => {
    global.setAppUI((prev) => ({ ...prev, section: route }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, global.setAppUI]);

  useEffect(() => {
    // Check if uid has been passed
    if (uid && global.stationList) {
      const matchingStation = global.stationList.find((station) => station.station === uid);
      global.setActiveStation(matchingStation);

      // Check if ssid has been passed
      if (matchingStation && ssid) {
        global.setAppDates((prev) => ({
          ...prev,
          timezone: matchingStation?.timezone ?? "Eastern Standard Time",
        }));

        // Sort streams alphabetically
        const sortedBoxes = sortMVPD(matchingStation?.boxes ?? []);

        // Find matching stream
        const matchedStream = sortedBoxes.find((box) => box.ssid === ssid);
        if (matchedStream) global.setActiveStream(matchedStream);

        if (startdate && enddate) {
          const newStart = moment(parseInt(startdate)).tz(getTz(global.appDates.timezone));
          const newEnd = moment(parseInt(enddate)).tz(getTz(global.appDates.timezone));

          if (newStart?.isValid() && newStart.format() !== global.appDates.start.format()) {
            global.setAppDates((prev) => ({ ...prev, start: newStart }));
          }
          if (newEnd?.isValid() && newEnd.format() !== global.appDates.end.format()) {
            global.setAppDates((prev) => ({ ...prev, end: newEnd }));
          }
        }
      }
    } else {
      global.setActiveStream(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, ssid, startdate, enddate, global.stationList, global.appDates.end, global.appDates.timezone]);

  const contentComponent = global.stationList ? (
    route === "overview" ? (
      <Overview route={route} />
    ) : (
      <SelectedStation route={route} />
    )
  ) : (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
      <Typography variant="body1" sx={{ marginTop: "1rem", color: "#36c", fontSize: "1.25rem" }}>
        Fetching Data from the Zeam API
      </Typography>
      <Typography variant="body1" sx={{ textAlign: "center", marginTop: "2rem", color: "#444" }}>
        If the site does not load within a reasonable period,
        <br />
        <Link href="https://www.minitool.com/news/clear-cache-for-one-site.html" target="_blank" style={{ color: "#1976d2 !important" }}>
          please try clearing your cache
        </Link>
      </Typography>
    </Container>
  );

  const handleClose = () => {
    localStorage.setItem("SyncMon-Modal", true);
    setShowModal(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("SyncMon-Modal")) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, []);

  const ModalComponent = () => {
    if (!modalActive) return null;
    return (
      <Dialog open={showModal} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{ display: "flex", alignItems: "center", color: "#36c" }}>
          <Info sx={{ color: "#36c", marginRight: "0.5rem" }} />
          Scheduled SyncMon Migration
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ marginBottom: "1rem" }}>
            Starting at 10am ET (9am CT) on October 9, <b>SyncMon</b> will begin migrating to a new environment as part of a larger Gray AWS project.
            Expectations are that the migration will be completed within 2 hours.
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            During this window, the site may be unavailable. We apologize for any inconvenience this may cause.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <React.Fragment>
      <Box className="syncmonitor" sx={{ display: "flex", flexDirection: "row", gap: "0", height: "100vh", width: "100vw" }}>
        <CssBaseline />
        <Header />
        <MainSidebar items={global.stationList} />
        <ModalComponent />
        {contentComponent}
      </Box>
    </React.Fragment>
  );
};

export default memo(AdminLayout);
