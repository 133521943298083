import React, { useContext, useState } from "react";

// MUI Components
import { ExpandLess, ExpandMore, LiveTvOutlined } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";

// AWS Components
import { DataStore } from "aws-amplify";
import { Streams } from "../../models";

// Gray Componets
import setActivityLog from "../../utilities/setActivityLog";
import { Application } from "../../context/Application";
import { getAutomation } from "../../utilities/utility";

const AdminAutomationSource = ({ streamData, forceUpdate }) => {
  const global = useContext(Application);

  /* Drop Down Elements */
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /* Admin Tool - Set Stream as Primary for ActiveSB */
  const setAutomation = async (data, automation) => {
    const original = await DataStore.query(Streams, (c) =>
      c.and((c) => [c.ssid.eq(+data.ssid), c.station.eq(data.sname.toLowerCase().replace(/-\D\D/, ""))])
    );

    try {
      if (original.length > 0 && original[0].hasOwnProperty("ssid")) {
        /* Update Record */
        // console.log("Update");
        console.log(
          await DataStore.save(
            Streams.copyOf(original[0], (updated) => {
              updated.automation = automation;
              updated.author = global.appUser.email;
            })
          )
        );
      } else {
        /* Create Record */
        // console.log("Create");
        console.log(
          await DataStore.save(
            new Streams({
              station: data.sname.toLowerCase().replace(/-\D\D/, ""),
              ssid: +data.ssid,
              activesb: data.activesb,
              automation: automation,
              author: global.appUser.email,
            })
          )
        );
      }

      await setActivityLog({
        type: "AUTOMATIONS",
        note: `Stream automation set to ${automation} for #${+data.ssid}`,
        author: global.appUser.email,
        station: global.activeStation.station,
      });

      // Refresh Navigation
      forceUpdate();
    } catch (e) {
      console.log("   - ERROR", e);
    }
  };
  return (
    <Box className="admin___panel_button">
      <Box className="title">
        <LiveTvOutlined fontSize="1rem" />
        <Typography>Automation Type</Typography>
      </Box>
      <Box className="description">
        <Typography>
          Define the automation platform for <b>{streamData.friendly}</b> to help streamline troubleshooting issues.
        </Typography>

        <Typography>
          <b>NOTE:</b> Administrator can still view all streams regardless of visibility setting.
        </Typography>
      </Box>
      <Box className="status">
        <Button
          id="automation-source-button"
          variant="outlined"
          aria-controls={open ? "automation-source-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          endIcon={open ? <ExpandLess /> : <ExpandMore />}
          size="small"
        >
          {open ? "Select Automation Type..." : streamData.automation ? getAutomation(streamData.automation) : "Select Automation"}
        </Button>
        <Menu
          id="automation-source-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {[
            { value: "ADC", name: "ADC" },
            { value: "CRISPIN", name: "Crispin" },
            { value: "DBI", name: "DBI" },
            { value: "FLORICAL", name: "Florical" },
            { value: "ITX", name: "ITX" },
            { value: "MORPHEUS", name: "Morpheus" },
            { value: "NVERZION", name: "NVerzion" },
            { value: "UNDEFINED", name: "Undefined" },
          ].map((item, key) => {
            return (
              <MenuItem
                key={item.value}
                onClick={() => {
                  handleClose();
                  setAutomation(streamData, item.value);
                }}
                sx={{
                  minWidth: "16.25rem",
                  fontSize: "0.875rem",
                  fontWeight: streamData?.automation === item.value ? 700 : "inherit",
                  color: streamData?.automation === item.value ? "rgba(15, 72, 255, 0.75)" : "inherit",
                }}
              >
                {item.name}
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    </Box>
  );
};

export default React.memo(AdminAutomationSource);
