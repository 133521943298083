/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createApplication = /* GraphQL */ `
  mutation CreateApplication(
    $input: CreateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    createApplication(input: $input, condition: $condition) {
      id
      appVersion
      releaseNotes
      releaseDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateApplication = /* GraphQL */ `
  mutation UpdateApplication(
    $input: UpdateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    updateApplication(input: $input, condition: $condition) {
      id
      appVersion
      releaseNotes
      releaseDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteApplication = /* GraphQL */ `
  mutation DeleteApplication(
    $input: DeleteApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    deleteApplication(input: $input, condition: $condition) {
      id
      appVersion
      releaseNotes
      releaseDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createScteAverages = /* GraphQL */ `
  mutation CreateScteAverages(
    $input: CreateScteAveragesInput!
    $condition: ModelScteAveragesConditionInput
  ) {
    createScteAverages(input: $input, condition: $condition) {
      id
      station
      ssid
      dotw
      hr_avgs
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateScteAverages = /* GraphQL */ `
  mutation UpdateScteAverages(
    $input: UpdateScteAveragesInput!
    $condition: ModelScteAveragesConditionInput
  ) {
    updateScteAverages(input: $input, condition: $condition) {
      id
      station
      ssid
      dotw
      hr_avgs
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteScteAverages = /* GraphQL */ `
  mutation DeleteScteAverages(
    $input: DeleteScteAveragesInput!
    $condition: ModelScteAveragesConditionInput
  ) {
    deleteScteAverages(input: $input, condition: $condition) {
      id
      station
      ssid
      dotw
      hr_avgs
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createScteAlerts = /* GraphQL */ `
  mutation CreateScteAlerts(
    $input: CreateScteAlertsInput!
    $condition: ModelScteAlertsConditionInput
  ) {
    createScteAlerts(input: $input, condition: $condition) {
      id
      station
      ssid
      status
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateScteAlerts = /* GraphQL */ `
  mutation UpdateScteAlerts(
    $input: UpdateScteAlertsInput!
    $condition: ModelScteAlertsConditionInput
  ) {
    updateScteAlerts(input: $input, condition: $condition) {
      id
      station
      ssid
      status
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteScteAlerts = /* GraphQL */ `
  mutation DeleteScteAlerts(
    $input: DeleteScteAlertsInput!
    $condition: ModelScteAlertsConditionInput
  ) {
    deleteScteAlerts(input: $input, condition: $condition) {
      id
      station
      ssid
      status
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      email
      name
      favorites
      showGPI
      showHidden
      showTelemundos
      appVersion
      lastLogin
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      email
      name
      favorites
      showGPI
      showHidden
      showTelemundos
      appVersion
      lastLogin
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      email
      name
      favorites
      showGPI
      showHidden
      showTelemundos
      appVersion
      lastLogin
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createStations = /* GraphQL */ `
  mutation CreateStations(
    $input: CreateStationsInput!
    $condition: ModelStationsConditionInput
  ) {
    createStations(input: $input, condition: $condition) {
      id
      station
      ssa
      hidden
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateStations = /* GraphQL */ `
  mutation UpdateStations(
    $input: UpdateStationsInput!
    $condition: ModelStationsConditionInput
  ) {
    updateStations(input: $input, condition: $condition) {
      id
      station
      ssa
      hidden
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteStations = /* GraphQL */ `
  mutation DeleteStations(
    $input: DeleteStationsInput!
    $condition: ModelStationsConditionInput
  ) {
    deleteStations(input: $input, condition: $condition) {
      id
      station
      ssa
      hidden
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createElementalNotes = /* GraphQL */ `
  mutation CreateElementalNotes(
    $input: CreateElementalNotesInput!
    $condition: ModelElementalNotesConditionInput
  ) {
    createElementalNotes(input: $input, condition: $condition) {
      id
      station
      note
      author
      status
      createdAt
      deletedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateElementalNotes = /* GraphQL */ `
  mutation UpdateElementalNotes(
    $input: UpdateElementalNotesInput!
    $condition: ModelElementalNotesConditionInput
  ) {
    updateElementalNotes(input: $input, condition: $condition) {
      id
      station
      note
      author
      status
      createdAt
      deletedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteElementalNotes = /* GraphQL */ `
  mutation DeleteElementalNotes(
    $input: DeleteElementalNotesInput!
    $condition: ModelElementalNotesConditionInput
  ) {
    deleteElementalNotes(input: $input, condition: $condition) {
      id
      station
      note
      author
      status
      createdAt
      deletedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createElementals = /* GraphQL */ `
  mutation CreateElementals(
    $input: CreateElementalsInput!
    $condition: ModelElementalsConditionInput
  ) {
    createElementals(input: $input, condition: $condition) {
      id
      station
      contact
      email
      address
      shippedAt
      model
      serialnumber
      version
      bmcCredentials
      internalIP
      rtmp
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateElementals = /* GraphQL */ `
  mutation UpdateElementals(
    $input: UpdateElementalsInput!
    $condition: ModelElementalsConditionInput
  ) {
    updateElementals(input: $input, condition: $condition) {
      id
      station
      contact
      email
      address
      shippedAt
      model
      serialnumber
      version
      bmcCredentials
      internalIP
      rtmp
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteElementals = /* GraphQL */ `
  mutation DeleteElementals(
    $input: DeleteElementalsInput!
    $condition: ModelElementalsConditionInput
  ) {
    deleteElementals(input: $input, condition: $condition) {
      id
      station
      contact
      email
      address
      shippedAt
      model
      serialnumber
      version
      bmcCredentials
      internalIP
      rtmp
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      station
      eventType
      note
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      station
      eventType
      note
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      station
      eventType
      note
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEncoders = /* GraphQL */ `
  mutation CreateEncoders(
    $input: CreateEncodersInput!
    $condition: ModelEncodersConditionInput
  ) {
    createEncoders(input: $input, condition: $condition) {
      id
      station
      activesb
      workflow
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEncoders = /* GraphQL */ `
  mutation UpdateEncoders(
    $input: UpdateEncodersInput!
    $condition: ModelEncodersConditionInput
  ) {
    updateEncoders(input: $input, condition: $condition) {
      id
      station
      activesb
      workflow
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEncoders = /* GraphQL */ `
  mutation DeleteEncoders(
    $input: DeleteEncodersInput!
    $condition: ModelEncodersConditionInput
  ) {
    deleteEncoders(input: $input, condition: $condition) {
      id
      station
      activesb
      workflow
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createStreamNotes = /* GraphQL */ `
  mutation CreateStreamNotes(
    $input: CreateStreamNotesInput!
    $condition: ModelStreamNotesConditionInput
  ) {
    createStreamNotes(input: $input, condition: $condition) {
      id
      station
      distro
      note
      author
      status
      createdAt
      deletedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateStreamNotes = /* GraphQL */ `
  mutation UpdateStreamNotes(
    $input: UpdateStreamNotesInput!
    $condition: ModelStreamNotesConditionInput
  ) {
    updateStreamNotes(input: $input, condition: $condition) {
      id
      station
      distro
      note
      author
      status
      createdAt
      deletedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteStreamNotes = /* GraphQL */ `
  mutation DeleteStreamNotes(
    $input: DeleteStreamNotesInput!
    $condition: ModelStreamNotesConditionInput
  ) {
    deleteStreamNotes(input: $input, condition: $condition) {
      id
      station
      distro
      note
      author
      status
      createdAt
      deletedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEventNotes = /* GraphQL */ `
  mutation CreateEventNotes(
    $input: CreateEventNotesInput!
    $condition: ModelEventNotesConditionInput
  ) {
    createEventNotes(input: $input, condition: $condition) {
      id
      station
      ssid
      note
      author
      status
      createdAt
      deletedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEventNotes = /* GraphQL */ `
  mutation UpdateEventNotes(
    $input: UpdateEventNotesInput!
    $condition: ModelEventNotesConditionInput
  ) {
    updateEventNotes(input: $input, condition: $condition) {
      id
      station
      ssid
      note
      author
      status
      createdAt
      deletedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEventNotes = /* GraphQL */ `
  mutation DeleteEventNotes(
    $input: DeleteEventNotesInput!
    $condition: ModelEventNotesConditionInput
  ) {
    deleteEventNotes(input: $input, condition: $condition) {
      id
      station
      ssid
      note
      author
      status
      createdAt
      deletedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPermissions = /* GraphQL */ `
  mutation CreatePermissions(
    $input: CreatePermissionsInput!
    $condition: ModelPermissionsConditionInput
  ) {
    createPermissions(input: $input, condition: $condition) {
      id
      email
      permission
      alt_email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePermissions = /* GraphQL */ `
  mutation UpdatePermissions(
    $input: UpdatePermissionsInput!
    $condition: ModelPermissionsConditionInput
  ) {
    updatePermissions(input: $input, condition: $condition) {
      id
      email
      permission
      alt_email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePermissions = /* GraphQL */ `
  mutation DeletePermissions(
    $input: DeletePermissionsInput!
    $condition: ModelPermissionsConditionInput
  ) {
    deletePermissions(input: $input, condition: $condition) {
      id
      email
      permission
      alt_email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createStreams = /* GraphQL */ `
  mutation CreateStreams(
    $input: CreateStreamsInput!
    $condition: ModelStreamsConditionInput
  ) {
    createStreams(input: $input, condition: $condition) {
      id
      station
      ssid
      activesb
      primary
      hidden
      category
      source
      author
      config
      input
      automation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateStreams = /* GraphQL */ `
  mutation UpdateStreams(
    $input: UpdateStreamsInput!
    $condition: ModelStreamsConditionInput
  ) {
    updateStreams(input: $input, condition: $condition) {
      id
      station
      ssid
      activesb
      primary
      hidden
      category
      source
      author
      config
      input
      automation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteStreams = /* GraphQL */ `
  mutation DeleteStreams(
    $input: DeleteStreamsInput!
    $condition: ModelStreamsConditionInput
  ) {
    deleteStreams(input: $input, condition: $condition) {
      id
      station
      ssid
      activesb
      primary
      hidden
      category
      source
      author
      config
      input
      automation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
