// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const StreamConfig = {
  "MIDDLEMAN": "MIDDLEMAN",
  "DBI": "DBI",
  "TBW": "TBW",
  "SNOWFLAKE": "SNOWFLAKE",
  "TIMED": "TIMED",
  "FULLSCTE": "FULLSCTE"
};

const ElementalStatus = {
  "SHIPPED": "SHIPPED",
  "CONFIGURED": "CONFIGURED",
  "LAUNCHED": "LAUNCHED",
  "TESTING": "TESTING",
  "UNUSED": "UNUSED",
  "OTHER": "OTHER"
};

const SourceTypes = {
  "MCR": "MCR",
  "PCR": "PCR",
  "ELEMENTAL": "ELEMENTAL",
  "BRIGHTSIGN": "BRIGHTSIGN",
  "JVC": "JVC",
  "OBS": "OBS",
  "WIRECAST": "WIRECAST",
  "DBI": "DBI",
  "WEATHERMAX": "WEATHERMAX",
  "WEATHERNATION": "WEATHERNATION",
  "CUSTOM": "CUSTOM",
  "VMIX": "VMIX",
  "SOFTRON": "SOFTRON"
};

const Workflow = {
  "B1_C1": "B1C1",
  "B1_C2": "B1C2",
  "B1_C3": "B1C3",
  "B1_C4": "B1C4"
};

const DistroTypes = {
  "GRAY": "GRAY",
  "AMAZON": "AMAZON",
  "ROKU": "ROKU",
  "VIZIO": "VIZIO",
  "SAMSUNG": "SAMSUNG"
};

const StatusTypes = {
  "OPEN": "OPEN",
  "SOLVED": "SOLVED",
  "PENDING": "PENDING",
  "BACKLOG": "BACKLOG"
};

const AutomationTypes = {
  "ADC": "ADC",
  "CRISPIN": "CRISPIN",
  "DBI": "DBI",
  "FLORICAL": "FLORICAL",
  "ITX": "ITX",
  "MORPHEUS": "MORPHEUS",
  "NVERZION": "NVERZION",
  "UNDEFINED": "UNDEFINED"
};

const PermissionTypes = {
  "SUPERADMIN": "SUPERADMIN",
  "ADMIN": "ADMIN",
  "MANAGER": "MANAGER"
};

const StreamInput = {
  "SOURCE1": "SOURCE1",
  "SOURCE2": "SOURCE2",
  "OTHER": "OTHER"
};

const { Application, ScteAverages, ScteAlerts, Users, Stations, ElementalNotes, Elementals, Activity, Encoders, StreamNotes, EventNotes, Permissions, Streams } = initSchema(schema);

export {
  Application,
  ScteAverages,
  ScteAlerts,
  Users,
  Stations,
  ElementalNotes,
  Elementals,
  Activity,
  Encoders,
  StreamNotes,
  EventNotes,
  Permissions,
  Streams,
  StreamConfig,
  ElementalStatus,
  SourceTypes,
  Workflow,
  DistroTypes,
  StatusTypes,
  AutomationTypes,
  PermissionTypes,
  StreamInput
};